import { ConfigStateService, featuresFactory, RoutesService, noop } from '@abp/ng.core';
import { InjectionToken, inject, APP_INITIALIZER } from '@angular/core';
import { setModuleVisibilityFactory } from '@volo/abp.commercial.ng.ui/config';
const FILE_MANAGEMENT_FEATURES = new InjectionToken('FILE_MANAGEMENT_FEATURES', {
  providedIn: 'root',
  factory: () => {
    const configState = inject(ConfigStateService);
    const featureKey = 'FileManagement.Enable';
    const mapFn = features => ({
      enable: features[featureKey]?.toLowerCase() === 'true'
    });
    return featuresFactory(configState, [featureKey], mapFn);
  }
});
const SET_FILE_MANAGEMENT_ROUTE_VISIBILITY = new InjectionToken('SET_FILE_MANAGEMENT_ROUTE_VISIBILITY', {
  providedIn: 'root',
  factory: () => {
    const routes = inject(RoutesService);
    const stream = inject(FILE_MANAGEMENT_FEATURES);
    setModuleVisibilityFactory(stream, routes, "FileManagement::Menu:FileManagement" /* eFileManagementRouteNames.FileManagement */).subscribe();
  }
});
const FILE_MANAGEMENT_FEATURES_PROVIDERS = [{
  provide: APP_INITIALIZER,
  useFactory: noop,
  deps: [SET_FILE_MANAGEMENT_ROUTE_VISIBILITY],
  multi: true
}];
const UPPY_OPTIONS = new InjectionToken('UPPY_OPTIONS');

/**
 * Generated bundle index. Do not edit.
 */

export { FILE_MANAGEMENT_FEATURES, FILE_MANAGEMENT_FEATURES_PROVIDERS, SET_FILE_MANAGEMENT_ROUTE_VISIBILITY, UPPY_OPTIONS };
